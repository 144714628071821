.smsBackImg{
    background: url("/src/images/sms-mobile.png");
    background-repeat: no-repeat;
    min-height: 500px;
    background-position: center;
}

.smsMainScreen {
    height: 265px;
    margin: 115px auto 0 auto;
    font-size: 12px;
    word-wrap: anywhere;
    width: 220px;
    overflow: hidden;
    padding-right: 30px;
    padding-left: 5px;
    overflow-y: hidden;
}

.smsPreviewMobile {
    background-color: #E7EBF3;
    border-radius: 5px;
    text-align: left;
    margin-right: 30px;
    width: 165px;
    word-wrap: anywhere;
    margin-bottom: 10px;
}

.ui-state-highlight{
    height:162.83;
    background-color: #fbf9ee;
    border: 1px solid #fcefa1;
}

.smsMainScreen{
    overflow-y: auto;
}

.cursorPointer{
    cursor: pointer
}
