.min-height-500 {
    min-height: 516px;
    padding-top: 35px;
}

.recordsTableContainer {
    width: 400px;
    background-color: #E1E1E1;
    margin-bottom: 30px;
}

.dataTableContainer {
    max-height: 600px;
    width: 100%;
    overflow: scroll;
    border: 1px solid rgb(153, 153, 153);
    box-shadow: rgb(0, 0, 0) 0px 2px 4px;
}

.tabelLabelColumn {
    padding-left: 4px;
    font-size: 14px;
}

.tableDataColumn {
    padding-left: 4px;
    font-size: 14px;
    width: 100px;
}

.tableLabels {
    height: 25px;
    background-color: #E1E1E1;
    padding-left: 4px;
}

.table-main {
    background-color: #E1E1E1;
    border-color: 1px solid #E1E1E1;
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
    width: 100%;
}
.popup-div{
    width: 90%;
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.import-box-types {
    min-height: 150px;
    border: 2px solid var(--website-color);
    padding: 15px;
    margin: 20px 20px 0px 0px;
    cursor: pointer;
    border-radius: 5px;
    width: 190px;
    display: inline-block;
}

.import-box-types:hover {
    background-color: var(--website-color);
    color: #FFFFFF;
}

.img-properties {
    height: 80px;
    width: 80px;
}

.tile-container {
    max-width: 700px;
}

.import-method-container {
    min-height: 300px;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
}

.modal-title {
    font-size: 22px;
    color: #000000;
}

.select-date-class {
    width: 490px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 25px;
}

.upload_instruction {
    width: 490px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 25px;
}

.info-icon {
    vertical-align: top;
}

.instruction-container {
    margin-top: 20px;
}

.select-date {
    width: 200px;
}

.dropdown-class {
    width: 100%;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    min-height: 300px;
}

.lable-style {
    font-size: 25px;
    font-weight: bold;
}

.fm-border-bottom {
    border-bottom: 1px solid #f5f5f5
}