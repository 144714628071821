.ComponentSidebarWrapper {
    max-width: 250px !important;
}
.Nodetitle {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.Nodetitle svg {
    width: 17px;
    height: 17px;
    margin-right: 15px;
    color: #fbbc12;
}
.nodeWrapper {
    height: auto;
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    color: black;
    border: 2px var(--website-color) solid;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-wrapper-main{
    display: flex;
    margin-left: 15px;
    width: 40px;
    height: 30px;
}
.button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
}
.startNodehandal {
    height: 15px !important;
    width: 15px !important;
    border: 0px !important;
}
.reactflow-wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 150px);
}
.reactflow-wrapper1 {
    display: flex;
    width: 100%;
    height: calc(100vh - 300px);
}
.sidebarcontainer {
    background-color: #535353;
    width: max-content;
    border: 1px solid #424242;
    box-shadow: 1px 1px 10px #424242;
    margin: 5px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    position: fixed;
    left: 15px;
}

.IconWrapper {
    font-size: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #535353;
}

.Icontitletxt {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
}

.sidebarBoxWrapper {
    padding: 5px 5px;
    margin: 10px 5px;
    cursor: pointer;
}

.sidebarSVGImg {
    color: #ffffff !important;
    width: 32px !important;
    height: 32px !important;
    font-Size: 24px !important;
    text-Align: center !important;
    fill: white !important;
}
.sidebartop {
    width: 100%;
    background-color: #424242;
    text-align: center;
    font-size: 20px;
    color: #fff;
    box-shadow: 1px 1px 10px #424242;
}

.pointLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
}

.pointLabelContainerSelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 10px 20px 10px 20px;
    background-color: var(--website-color);
    cursor: pointer;
}

.pointLabelContainer:hover {
    background-color: #d3d3d3;
}

.subPointsContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    transition: height 2s;
}

.subPointLabelContainer {
    padding: 8px 0px 8px 35px;
    cursor: pointer;
}

.subPointLabelContainer:hover {
    background-color: #d3d3d3;
}

.subPointLabelContainerSelected {
    padding: 8px 0px 8px 35px;
    background-color: var(--website-color);
    cursor: pointer;
}

.email-template-card {
    position: relative;
    overflow: hidden;
    height: 300px;
    width: 48%;
    border: 1px solid #d5d5d6;
}

.email-template-card.active-tmpt {
    border: 1px solid #016ca2 !important;
    box-shadow: none !important;
}

.email-template-card:hover {
    box-shadow: 8px 12px 31px -10px #ab98ab;
    border: 1px solid #e5ad2a;
}

.email-template-card-img-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    height: 270px;
    overflow: hidden;
}

.email-template-card-img-top {
    width: 100%;
}

.email-template-card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: #fbfbfb;
    transition: 1.5s ease;
    align-items: center;
    justify-items: center;
    padding: 5px;
}

.email-template-card-title {
    transition: 1.5s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-bottom: 0px;
}

.email-template-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.starting-point-container {
    width: 40vw;
    padding: 20px;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.heading-style {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    color: var(--website-color);
}

.cross-icon-container {
    display: flex;
    justify-content: flex-end;
    padding: 30px 50px 0px 0px;
}

.time-duration-box {
    padding: 7px 10px 7px 10px;
    border: 1px solid #d3d3d3;
    cursor: pointer;
}

.time-duration-box.active {
    background-color: var(--website-color);
    color: #ffffff;
}
.actionSidebarContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.actionSidebarBtnContainer{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 10px 0px 0px 0px;
}

.btnContainer{
    margin-top: 10px;
    position: fixed;
    right: 30px;
    z-index: 100;
}
[data-handleid="yes"]:before{
    content: "Yes";
    font-size: 9px;
    position: absolute;
    left: -20px;
    bottom: -8px;
}
[data-handleid="no"]:before{
    content: "No";
    font-size: 9px;
    position: absolute;
    left: 20px;
    bottom: -8px;
}